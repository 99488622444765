const favoriteCollections = [
    {
        slug: 'tshirts',
        menuTitle: 'T-Shirts',
        intlId: 'collection.tshirts'
    },
    {
        slug: 'smartphone',
        menuTitle: 'Smartphone',
        intlId: 'collection.smartphone'
    },
    {
        slug: 'magazines',
        menuTitle: 'Magazines',
        intlId: 'collection.magazines'
    },
    {
        slug: 'books',
        menuTitle: 'Books',
        intlId: 'collection.books'
    },
    {
        slug: 'laptop',
        menuTitle: 'Laptop',
        intlId: 'collection.laptop'
    },
    {
        slug: 'frames',
        menuTitle: 'Frames',
        intlId: 'collection.frames'
    },
    {
        slug: 'mugs',
        menuTitle: 'Mugs',
        intlId: 'collection.mugs'
    },
    {
        slug: 'face-masks',
        menuTitle: 'Face Masks',
        intlId: 'collection.facemasks'
    },
    {
        slug: 'tablet',
        menuTitle: 'Tablet',
        intlId: 'collection.tablet'
    },
    {
        slug: 'desktop',
        menuTitle: 'Desktop',
        intlId: 'collection.desktop'
    }
];

export default favoriteCollections;
