import React from 'react';
import PropTypes from 'prop-types';
import Filter from './Filter';
import styles from './filter.module.scss';

const FilterGroup = (props) => {
    const {filters, active, filtersUrls, onFiltersChange, hideAllOption, isPrimary} = props;

    const getFiltersUrls = (tag, index) => {
        let active = getAllActiveFilters();
        active[index] = tag;
        active = active.filter((item) => {
            return item !== '';
        });

        return filtersUrls(active);
    };

    const getActiveFilterForGroup = (filter) => {
        const found = filter.find( item => { return active.indexOf(item.slug) > -1 } );

        return found ? found.slug : '';
    };

    const getAllActiveFilters = () => {
        return filters.map((filter) => {
            return getActiveFilterForGroup(filter);
        });
    };

    const renderFilters = () => {
        return filters.map((tagGroup, index) => {
            const tagKey = tagGroup.length - index;
            return (
                <Filter
                    key={`filtergroup-${tagKey}`}
                    filters={tagGroup}
                    active={active}
                    hideAllOption={hideAllOption}
                    onFilterChange={onFiltersChange}
                    filtersUrls={(tag) => getFiltersUrls(tag, index)}
                />
            );
        });
    };

    if (!filters || filters.length === 0) return null;

    return <div className={`${styles.filters} ${isPrimary ? styles.filterWrapper : null}`}>{renderFilters()}</div>;
};

FilterGroup.propTypes = {
    filters: PropTypes.array,
    active: PropTypes.array,
    onFiltersChange: PropTypes.func,
    filtersUrls: PropTypes.func,
    hideAllOption: PropTypes.bool,
    isPrimary: PropTypes.bool
};

export default FilterGroup
