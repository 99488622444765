import { useState } from 'react';

const useSlider = (sliderRef, wrapperRef) => {
    // to set the movement offset
    const [currentOffset, setCurrentOffset] = useState(0);
    // maximum movement offset to right side
    const [maxOffset, setMaxOffset] = useState(-1);

    // meant to hide arrows controls for touch devices
    // Note: not used currently, control arrows should be accessible correctly
    // even by touch events
    // const canHover = () => {
    //     if (typeof window === 'undefined') return false;
    //     return !window.matchMedia('(hover: none)').matches;
    // };

    /**
     * Reset slider
     * 1] set slider to start -> current offset to zero
     * 2] set new max offset from current slider DOM elemenets sizes & positions
     */
    const resetOffset = () => {
        setCurrentOffset(0);
        const newMaxOffset = calculateMaxOffset();
        setMaxOffset(newMaxOffset);
    };

    /**
     * Calculates maximal movement as difference between
     * right position of slider wrapper minus slider fullwidth
     * Note: is calculated freshly during next button click to ensure
     * having always current viewport data (e.g. if window has been resized)
     * @returns {number}
     */
    function calculateMaxOffset() {
        const slider = sliderRef.current.getBoundingClientRect();
        const wrapper = wrapperRef.current.getBoundingClientRect();
        const newMaxOffset = Math.floor(slider.width - wrapper.width);
        return newMaxOffset;
    }

    /**
     * Handle click on previous Icon
     * set new movement offset
     * with check - cant be higher > 0
     */
    const handlePrev = () => {
        const wrapper = wrapperRef.current.getBoundingClientRect();
        const movement = wrapper.width / 2;
        const newCurrentOffset = Math.max(currentOffset - movement, 0);

        setSliderScrollPosition(newCurrentOffset);
    };

    /**
     * Handle click on next Icon
     * set new movement offset
     * check for possible movement limit, set eventually maximal available offset
     */
    const handleNext = () => {
        const wrapper = wrapperRef.current.getBoundingClientRect();
        const movement = Math.floor(wrapper.width / 2);
        const newMaxOffset = calculateMaxOffset();
        const newCurrentOffset = Math.min(currentOffset + movement, newMaxOffset);

        setMaxOffset(newMaxOffset);

        setSliderScrollPosition(newCurrentOffset);
    };

    /**
     * Set a new scroll position of slidebar
     * @param scrollToLeft
     */
    const setSliderScrollPosition = (scrollToLeft) => {
        wrapperRef.current.scrollTo({
            left: scrollToLeft,
            behavior: 'smooth'
        });
    };

    const handleOnScroll = () => {
        setCurrentOffset(wrapperRef.current.scrollLeft);
    };

    return {
        maxOffset,
        currentOffset,
        handleNext,
        handlePrev,
        resetOffset,
        handleOnScroll
    };
};

export default useSlider;
