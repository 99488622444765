import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Searchbar.module.scss';
import { DebounceInput } from 'react-debounce-input';
import Icon from '../../Base/Icons/Icon';
import Button from '../../Base/Button/Button';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import Router from 'next/router';
import { trackEvent } from '../../../utils/trackingParty';
import mockupsData from '../../../public/mockups.json';
import getLocaleCodeLong from "../../../utils/locale/getLocaleCodeLong";

const algoliasearch = require('algoliasearch');

const client = algoliasearch('CHOYNIXSLK', '137a0fb339d1422645b68c2d1eaafd20');
const index = client.initIndex('collections');

const Searchbar = (props) => {
    const messages = defineMessages({
        placeholder: {
            id: 'components.SearchBar.placeholder',
            defaultMessage: 'Search {count}+ online mockups'
        }
    });

    const [searchResults, setSearchResults] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(0);
    const [notResults, setNotResults] = useState(false);

	const setSelectedSuggestionClamped = val => {
		val = Math.max(0, Math.min(val, searchResults.length))
		setSelectedSuggestion(val)
	}

    const ref = useRef();
    const searchRef = useRef(null);

    useEffect(() => {
        searchRef.current = props.value;
        setOpen(false);
    }, [props]);

    useEffect(() => {
        const onWindowClick = (e) => {
            if (!ref.current.contains(e.target)) {
                handleClickOutside();
            }
        };
        window.addEventListener('mousedown', onWindowClick);
        window.addEventListener('touchstart', onWindowClick);
        return () => {
            window.removeEventListener('mousedown', onWindowClick);
            window.removeEventListener('touchstart', onWindowClick);
        };
    }, []);

    const handleKeyPress = (e) => {
        switch (e.keyCode) {
            // Enter pressed, confirm search
			case 13: return handleEnterPress();
			// arrow up
			case 38: return setSelectedSuggestionClamped(selectedSuggestion - 1);
			// arrow down
			case 40: return setSelectedSuggestionClamped(selectedSuggestion + 1);
			// left
			case 37: return;
			// right
			case 39: return;
			// reset
			default: return setSelectedSuggestion(0);
        }
    };

    const handleClickOutside = () => {
        setOpen(false);
    };

    const handleChange = async (e) => {
        const shortTerms = ['tv', 'cd'];
        const value = e.target.value.replace(/\s\s+/g, ' ').trim();

        // skip most of double letter words
        if (value.length === 2 && shortTerms.indexOf(value) === -1 || onlySpaces(value) === true) {
            setSearchResults([]);
            searchRef.current = null;
            setOpen(false);
            return;
        }

        searchRef.current = value;

        const results = await index.search(value, {
            hitsPerPage: 10
        });

        const filtered = results.hits.filter((item) => {
            return item.isActive !== false && item.isRoot !== true;
        });

        if (filtered.length > 0) {
            setSearchResults(filtered);
            setNotResults(false);
        } else {
            setSearchResults([]);
            setNotResults(true);
        }
        setOpen(true);

        // we do not want to redirect to results page immediately after search
        // we just use the results to the search suggestions on the page
        // instead log the search event right here
        trackEvent('Search', { query: value });
    };

    const handleCloseClick = () => {
        searchRef.current = null;
        setOpen(false);
        Router.push('/mockups');
    };

    const getHighlightedTitle = (collection) => {

        const localisedResultValue = collection._highlightResult[`collectionTitle-${getLocaleCodeLong(props.intl.locale)}`];

        /* eslint no-underscore-dangle: 0 */
        return `${
            props.intl.locale !== 'en' && localisedResultValue
                ? localisedResultValue.value
                : collection._highlightResult.collectionTitle.value
        }`;
    };

	const getCollectionUrl = collection => `/mockups/${collection.slug}`;

	const handleEnterPress = () => {
		if (selectedSuggestion !== -1 && selectedSuggestion < searchResults.length && searchRef.current) {
			const collection = searchResults[selectedSuggestion];
			const url = getCollectionUrl(collection);
	        Router.push(url, url);
		}
        /*
        else {
			handleSearch();
		}

         */
	}

    // we redirect to root mockups page
    // we do not keep the path in current collection
    // as we search within whole mockups database
    /*
    const handleSearch = () => {
	    if(searchRef.current) {
            const { query } = Router.router;

        delete query.slug;
        const urlParams = new URLSearchParams(query);
        urlParams.delete('page');

            urlParams.set('search', searchRef.current);
            const q = `?${urlParams.toString()}`;

            const href = `/mockups/${q}`;
            const as = `/mockups/${q}`;

            Router.push(href, as);
        }
    };

     */

    const handleFocus = () => {
        if (searchRef.current) setOpen(true);
    };

    function onlySpaces(str) {
        return /^\s*$/.test(str);
    }

    const { value, intl } = props;
    return (
        <div className={`${styles.searchContainer} ${open ? styles.open : undefined}`}>
            <div className={styles.wrapper} ref={(node) => (ref.current = node)}>
                <div className={styles.selector}>
                    {value && value.length > 0 && (
                        <Button className={styles.close} design="text" onClick={handleCloseClick}>
                            <Icon name="Close" />
                        </Button>
                    )}
                    <DebounceInput
                        className={styles.input}
                        minLength={2}
                        maxLength={30}
                        debounceTimeout={400}
                        onChange={handleChange}
                        placeholder={intl.formatMessage(messages.placeholder, {
                            count: mockupsData.roundedCount
                        })}
                        value={value || ''}
                        onClick={handleFocus}
                        onKeyDown={handleKeyPress}
                        ref={searchRef}
                    />
                    <div className={styles.icon}>
                        <Icon name="Search" width={15} height={15} />
                    </div>
                </div>

                {open && searchRef.current && (
                    <div className={styles.selectOptions} onMouseLeave={() => setSelectedSuggestion(-1)}>
                        <span className={styles.divider} />
                        {searchResults.map((collection, i) => (
                            (collection._highlightResult.collectionTitle && collection.isActive) && (
                                <div key={collection.slug} className={styles.selectOption}
                                     onMouseEnter={() => setSelectedSuggestion(i)}>
                                    <Link to={getCollectionUrl(collection)}
                                          className={selectedSuggestion === i ? styles.selected : undefined}>
									<span
                                        /* eslint react/no-danger: 0 */
                                        dangerouslySetInnerHTML={{
                                            __html: getHighlightedTitle(collection)
                                        }}
                                    />
                                    </Link>
                                </div>
                            )))}
                        {notResults && (
                            <div className={`${styles.searchOption} ${styles.notResults}`}>
                                <span>
                                    <FormattedMessage defaultMessage="Sorry we couldn't find any matching mockups... Please adjust your search and try again." />
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
export default injectIntl(Searchbar);
Searchbar.propTypes = {
    value: PropTypes.string
};
