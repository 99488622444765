import React from 'react';
import PropTypes from 'prop-types';
import styles from './filter.module.scss';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import { FormattedMessage } from 'react-intl';

const Filter = (props) => {
    const {filters, active, onFilterChange, filtersUrls, hideAllOption} = props;

    const getFilterUrl = (slug) => {
        return filtersUrls(slug);
    };

    const isAnyItemActive = () => {
        let result = false;
        filters.map((item) => {
            if (isActive(item.slug)) {
                result = true;
            }
            return null;
        });
        return result;
    };

    const isActive = (slug) => {
        if (!active) return false;
        return active.indexOf(slug) > -1;
    };

    const renderButtons = () => {
        return filters.map((item) => {
            return (
                <li key={item.slug} className={styles.filterItem} role="menuitem">
                    <Link
                        to={getFilterUrl(item.slug)}
                    >
                        <button
                            className={
                            isActive(item.slug)
	                            ? `font-bold ${styles.filterButtonActive}`
	                            : `font-regular ${styles.filterButton}`
                            }
                            value={item.slug}
                            onClick={onFilterChange}
                        >
	                        {/* collections have menuTitle property instead of title */}
                            {item.menuTitle ? item.menuTitle : item.title}
                        </button>
                    </Link>
                </li>
            );
        });
    };

    if (!filters || filters.length === 0) return null;

    return (
        <ul className={styles.filter} role="menubar">
            {!hideAllOption && (
                <li className={styles.filterItem} role="menuitem">
                    <Link to={getFilterUrl("")}>
                        <button
                            className={
                                !isAnyItemActive()
	                                ? `font-bold ${styles.filterButtonActive}`
	                                : `font-regular ${styles.filterButton}`
                            }
                            value=""
                            onClick={onFilterChange}
                        >
                            <FormattedMessage defaultMessage="All" id="global.filter.all" />
                        </button>
                    </Link>
                </li>
            )}
            {renderButtons()}
        </ul>
    );
};

Filter.propTypes = {
    filters: PropTypes.array,
    active: PropTypes.array,
    onFilterChange: PropTypes.func,
    filtersUrls: PropTypes.func,
    hideAllOption: PropTypes.bool
};

export default Filter;
