import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './SlideBar.module.scss';
import dynamic from 'next/dynamic';
import useSlider from './useSlider';

const ControlArrows = dynamic(() => import('./ControlArrows'), { ssr: false });

const SlideBar = (props) => {
    // slider bar ref - to know the full bar width
    const sliderRef = useRef(null);
    // slider wrapper ref - to know the visible slider width
    const wrapperRef = useRef(null);

    const {
        maxOffset,
        currentOffset,
        handleNext,
        handlePrev,
        resetOffset,
        handleOnScroll
    } = useSlider(sliderRef, wrapperRef);

    // We need to reset current offset during rerender
    useEffect(() => {
        resetOffset();
    }, [props]);

    /**
     * Set initial max offset - this indicates if arrows should be shown
     * Set the listener for window resize to update the max offset, for arrows to show/hide
     */
    useEffect(() => {
        resetOffset();
        window.addEventListener('resize', resetOffset);
        return () => window.removeEventListener('resize', resetOffset);
    }, []);

    return (
        <div className={styles.outerContainer}>
            <ControlArrows
                onNext={handleNext}
                onPrev={handlePrev}
                maxOffset={maxOffset}
                currentOffset={currentOffset}
                isEnabled
                arrowsPosition={props.arrowsPosition}
            />
            <div onScroll={handleOnScroll} className={`${styles.sliderWrapper}`} ref={wrapperRef}>
                <div className={styles.container}>
                    <div ref={sliderRef} className={styles.slidebar}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SlideBar;

SlideBar.propTypes = {
    arrowsPosition: PropTypes.oneOf(['inside', 'outside']),
    children: PropTypes.object.isRequired
};
